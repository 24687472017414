import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const rendezacesar = () => (
  <Layout>
    <SEO title="Sentier et Chemin - Rendez à César" />
    <h3 className='underline-title'>Rendez à César...</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>        
        Les César réclament<br />
        Les César du siècle<br />
        Ils réclament aux-mêmes leur denier<br />
        Denier frappé à leur effigie<br />
      </p>
      <p>
        Depuis que le Rabbi monta<br />
        Depuis qu'Il disparut<br />
        Du regard terrestre<br />
        Dans les célestes nues<br />
        Il n'y a personne dès lors<br />
        Sincèrement personne de la terre<br />
        Pour rendre le denier impéral<br />
        Aux César propriétaires<br />
      </p>
      <p>
        Les César s'impatientent<br />
        Ils réclament aux-mêmes leur denier<br />
        Denier frappé à leur effigie<br />
        C'est leur denier<br />
        Rendez-leur leur denier<br />
        Denier frappé à leur effigie<br />
      </p>
      <p>
        Après avoir rendu leur denier<br />
        Allez à Espergaerde-lez-Copenhague<br />
        Revenez à Tiregand périgourdin par Paris<br />
        Séjournez à Bukavu pour descendre à Bunyakiri<br />
        Vous y verrez:<br />
        <span className="par-starting"></span>le gazon Mystique d'Espergaerde<br />
        <span className="par-starting"></span>la mystérieuse Dordogne du Périgord<br />
      </p>
      <p>
        Ils vous révéleront le meme secret:<br />
        Secret des cimes extasiées des forets de Bunyakiri<br />
        Au-dessus de vierges forets<br />
        Plus haut que les cimes pamées d'extase <br />
        S'épand l'altier éther<br />
        Accrochez-vous a cette onde véhiculaire<br />
        Vous devenez ainsi puissant<br />
        Plus puissant que les César de deniars<br />
      </p>
      <p>
        Les César réclament<br />
        Rendez-leur leur denier<br />
        Rendez l'Onde à sa Source<br />
      </p>
    </div>
    <br />
    <br />
    <Link to="/sentier-et-chemin-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default rendezacesar
